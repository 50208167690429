<template>
<div>
  <div class="d-flex vh-main text-center">
    <div class="col-12 my-auto">
      This service is in limited availability, please wait while you access get approved. Thanks!
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    event: Object,
    params: Object,
  }
}
</script>